import React from 'react';
import Layout from '../components/layout';
import Helmet from 'react-helmet';

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <main>
        <h1>get in touch :)</h1>
        <p>
          <strong>email:</strong>
          <code> hello</code> &#64; [<em>domain of this website</em>]
        </p>
        <p>
          <strong>twitter:</strong>{' '}
          <a href="https://twitter.com/nikulisf" target="_blank" rel="noopener">
            @nikulisf
          </a>
        </p>
      </main>
    </Layout>
  );
};

export default BlogPage;
